










































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import LoadingState from "@/enums/APISTATE";
import { ToastProgrammatic as toast } from "buefy";

function createTeacher() {
  return {
    department: null,
    reL_Department: null,
    firstName: "",
    lastName: "",
    initials: "",
  };
}

export default Vue.extend({
  name: "teacher-info",
  data: () => ({
    teacher: createTeacher(),
    firstName: null,
    lastName: null,
    selectedDepartment: null,
  }),
  mounted() {
    const id = this.$route.params.id;

    if (id && id !== 'new') {
      this.teacher = this.getTeacher(id);

      // It probably looks strange when the teachers name changes as we edit?
      this.firstName = this.teacher.firstName;
      this.lastName = this.teacher.lastName;
    }
  },
  computed: {
    ...mapGetters("teachers", ["state", "getTeacher"]),
    ...mapGetters("departments", ["departments"]),
    ...mapGetters({
      isLoadingTeachers: "teachers/isLoading",
    }),
  },
  methods: {
    async save(event) {
      event.preventDefault();

      if (!this.teacher.id) {
        await this.createTeacher(this.teacher);
      } else {
        await this.updateTeacher(this.teacher);
      }

      if (this.state === LoadingState.LOADED) {
        this.$router.push("/teachers");
        toast.open({
          type: "is-success",
          message: "Underviser oprettet",
        });
      } else {
        toast.open({
          type: "is-danger",
          message: "Kunne ikke oprette underviser",
        });
      }
    },
    ...mapActions("teachers", ["createTeacher", "updateTeacher"]),
  },
});
